<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
body:after{
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("assets/bg1080.png");
  background-size: 100% 100%;
  /*opacity: 0.8;*/
  z-index: -1;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 120px;
}
.box {
  /*margin-top: 120px;*/
  padding: 10px;
  background-color: rgba(255,255,255,0.4);
}
.box-title {
  margin: 20px 0;
}
.i-icon {
  color: rgba(0,0,0,.25);
}
</style>
